<template>
  <div class="park-activity-dialog">
    <el-form
      :model="ruleForm"
      :rules="rules"
      label-position="top"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-row v-show="currentState === 'review'" :gutter="48">
        <el-col :span="12">
          <el-form-item label="回顾活动名" prop="reviewActivityName">
            <el-select
              ref="tagListRef"
              @change="reviewListChange"
              style="width: 100%"
              v-model="reviewValue"
              filterable
              remote
              clearable
              reserve-keyword
              placeholder="请输入活动名称"
              :remote-method="getParkActivityHandler"
              :loading="reviewLoading"
            >
              <el-option
                v-for="item in reviewOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-show="reviewValue ? true : false">
          <el-form-item
            label="覆盖活动预告"
            prop="coverFlag"
            :rules="
              (reviewValue !== '' || reviewValue !== null) && [
                {
                  required: true,
                  message: '请选择是否覆盖活动预告',
                  trigger: 'blur',
                },
              ]
            "
          >
            <span class="review-radio-container">
              <el-radio-group
                style="display: flex"
                v-model="ruleForm.coverFlag"
                border
              >
                <el-radio label="0" border>否</el-radio>
                <el-radio label="1" border>是</el-radio>
              </el-radio-group>
              <span class="review-tips"
                >(覆盖后小程序将不再展示该活动预告)</span
              >
            </span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="48">
        <el-col :span="12">
          <el-form-item label="活动标题" prop="activityTitle">
            <el-input
              :disabled="currentState === 'view'"
              maxlength="50"
              v-model="ruleForm.activityTitle"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发布时间" prop="pubdate">
            <el-date-picker
              :disabled="currentState === 'view'"
              style="width: 100%"
              v-model="ruleForm.pubdate"
              type="datetime"
              value-format="timestamp"
              placeholder="选择发布时间"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="活动类型" prop="activityType">
            <el-select
              :disabled="currentState === 'view'"
              style="width: 100%"
              v-model="ruleForm.activityType"
            >
              <el-option
                v-for="(item, index) in activityTypeList"
                :value="item.value"
                :label="item.label"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="展示位置" prop="showAddress">
            <el-select
              :disabled="currentState === 'view'"
              style="width: 100%"
              v-model="ruleForm.showAddress"
            >
              <el-option
                v-for="(item, index) in showAddressList"
                :value="item.value"
                :label="item.label"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开始时间" prop="startTime">
            <el-date-picker
              :disabled="currentState === 'view'"
              style="width: 100%"
              v-model="ruleForm.startTime"
              type="datetime"
              value-format="timestamp"
              placeholder="选择开始时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="结束时间" prop="endTime">
            <el-date-picker
              :disabled="currentState === 'view'"
              style="width: 100%"
              v-model="ruleForm.endTime"
              type="datetime"
              value-format="timestamp"
              placeholder="选择结束时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="活动地址" prop="activityAddress">
            <el-input
              :disabled="currentState === 'view'"
              v-model="ruleForm.activityAddress"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="发布人" prop="publisher">
            <el-input disabled v-model="ruleForm.publisher"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item
          label="封面图片"
          prop="photoIdListId"
          class="auto-height-item"
        >
          <div class="upload-container" v-loading="uploadLoading">
            <el-upload
              :disabled="currentState === 'view'"
              class="avatar-uploader"
              :action="uploadPath"
              :with-credentials="true"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="imageUrl"
                @click.stop="handlePictureCardPreview"
                :src="imageUrl"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="tips-container">
              <div>
                <div>
                  温馨提示：最多上传1张，图片大小不能超过2m，图片格式为jpg，png。
                </div>
                <div>图片尺寸212*112</div>
              </div>
              <div
                v-show="
                  currentState !== 'view' && ruleForm.photoIdListId.length > 0
                "
                class="delete-icon"
                @click="deleteHandler"
              >
                <i class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="链接地址" prop="linkAddress">
          <el-input
            :disabled="currentState === 'view'"
            v-model="ruleForm.linkAddress"
          ></el-input>
        </el-form-item>
      </el-row>
      <el-form-item align="right" v-if="currentState !== 'view'">
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确定</el-button
        >
        <el-button @click="resetForm()">取消</el-button>
      </el-form-item>
      <el-form-item align="right" v-if="currentState === 'view'">
        <el-button @click="changeStateToEdit" type="primary">编辑</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="dialogVisible" :modal="false">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";
import { findProfile } from "@/api/ruge/sso/personal/personal.js";
import {
  addParkActivity,
  getParkActivityList,
  getParkActivityDetail,
  editParkActivity,
} from "@/api/ruge/bms/customerService/parkActivity";
import { checkUrl } from "@/utils/utils.js";
export default {
  name: "parkNoticeDialog",
  props: {
    activityTypeList: {
      type: Array,
      required: true,
    },
    showAddressList: {
      type: Array,
      required: true,
    },
    dialogStatus: {
      type: String,
      required: true,
    },
    id: {
      required: false,
    },
    typeFlag: {
      required: false,
    },
  },
  watch: {
    dialogStatus: {
      handler(val) {
        this.currentState = val;
        if (val === "review") {
          this.getParkActivityHandler();
        }
      },
      immediate: true,
    },
    id: {
      handler(id) {
        if (!id) return;
        this.getActivityDetail(id);
      },
      immediate: true,
    },
  },
  created() {
    if (this.dialogStatus === "add" || this.dialogStatus === "review") {
      this.initCurrentUserName();
    }
  },
  data() {
    const validatorStarttime = (rule, value, callback) => {
      if (this.currentState === "view") {
        callback();
        return;
      }
      if (!value) {
        callback(new Error("开始时间为必填"));
        return;
      }
      if (value >= this.ruleForm.endTime) {
        callback(new Error("开始时间必须小于结束时间"));
      } else {
        // this.$refs.ruleForm.validateField("endTime");
        callback();
      }
    };
    const validatorEndtime = (rule, value, callback) => {
      if (this.currentState === "view") {
        callback();
        return;
      }
      if (!value) {
        callback(new Error("结束时间为必填"));
        return;
      }
      if (value <= this.ruleForm.startTime) {
        callback(new Error("结束时间必须大于开始时间"));
      } else {
        this.$refs.ruleForm.validateField("startTime");
        callback();
      }
    };
    const validatorPhoto = (rule, value, callback) => {
      if (this.currentState === "view") {
        callback();
        return;
      }
      if (this.ruleForm.photoIdListId.length === 0) {
        callback(new Error("封面图片字段为必填"));
      } else {
        callback();
      }
    };
    const validatorLinkAddress = (rule, value, callback) => {
      if (this.currentState === "view") {
        callback();
        return;
      }
      if (!value) {
        callback(new Error("链接地址为必填"));
      } else {
        callback();
      }
      // checkUrl(value)
      //   .then((res) => {
      //     if (res) {
      //       callback();
      //     } else {
      //       callback(new Error("链接地址无效"));
      //     }
      //   })
      //   .catch((errorCode) => {
      //     if (errorCode === 1) {
      //       callback(new Error("链接地址为必填"));
      //     } else {
      //       callback(new Error("链接地址无效"));
      //     }
      //   });
    };
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      reviewId: null,
      reviewLoading: false,
      reviewOptions: [],
      reviewValue: null,
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() < Date.now() - 1 * 24 * 60 * 60 * 1000 ||
            time.getTime() > Date.now() + 3 * 31 * 24 * 60 * 60 * 1000
          );
        },
      },
      startTimeOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 1 * 24 * 60 * 60 * 1000;
        },
      },
      currentState: "add",
      uploadLoading: false,
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
      imageUrl: null,
      activityNameList: [],
      ruleForm: {
        activityTitle: null,
        pubdate: null,
        showAddress: null,
        publisher: null,
        activityType: null,
        startTime: null,
        endTime: null,
        activityAddress: null,
        photoIdListId: [],
        linkAddress: null,
        coverFlag: "0",
      },
      rules: {
        activityTitle: [
          { required: true, message: "活动标题为必填项", trigger: "blur" },
        ],
        pubdate: [
          { required: true, message: "发布时间为必填", trigger: "blur" },
        ],
        showAddress: [
          { required: true, message: "展示位置为必填", trigger: "change" },
        ],
        activityType: [
          { required: true, message: "活动类型为必填", trigger: "change" },
        ],
        startTime: [
          { required: true, validator: validatorStarttime, trigger: "change" },
        ],
        endTime: [
          { required: true, validator: validatorEndtime, trigger: "change" },
        ],
        activityAddress: [
          { required: true, message: "活动地址为必填项", trigger: "blur" },
        ],
        photoIdListId: [{ required: true, validator: validatorPhoto }],
        linkAddress: [
          {
            required: true,
            validator: validatorLinkAddress,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = this.imageUrl;
      this.dialogVisible = true;
    },
    initCurrentUserName() {
      findProfile({ userAccount: this.$store.getters.userAccount }).then(
        (r) => {
          this.ruleForm.publisher = r.userName;
        }
      );
    },
    changeStateToEdit() {
      this.currentState = "edit";
      this.initCurrentUserName();
    },
    reviewListChange(val) {
      this.reviewId = val;
      val && this.getActivityDetail(val);
    },
    getActivityDetail(id) {
      getParkActivityDetail({ id }).then((res) => {
        if (res.code === 200) {
          if (this.currentState === "view" || this.currentState === "edit") {
            this.ruleForm.publisher = res.data.publisher;
          }
          this.ruleForm.activityTitle = res.data.activityTitle;
          this.ruleForm.activityAddress = res.data.activityAddress;
          this.ruleForm.activityType = res.data.activityType;
          this.ruleForm.pubdate = new Date(res.data.pubdate).getTime();
          this.ruleForm.startTime = new Date(res.data.startTime).getTime();
          this.ruleForm.endTime = new Date(res.data.endTime).getTime();
          this.ruleForm.showAddress = res.data.showAddress;
          this.ruleForm.linkAddress = res.data.linkAddress;
          this.ruleForm.photoIdListId = res.data.photoIdListId;
          this.imageUrl = res.data.photoIdListId[0]
            ? envInfo.bgApp.archivePath +
              "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
              res.data.photoIdListId[0]
            : "";
        } else {
          this.$message.warning("获取活动详情失败！");
        }
      });
    },
    getParkActivityHandler(title) {
      this.reviewLoading = true;
      const params = {
        current: 1,
        rowCount: 20,
        activityTitle: title || null,
        typeFlag: 1,
      };
      getParkActivityList(params)
        .then((res) => {
          this.reviewOptions = res.data.rows.map((item) => {
            return {
              value: item.id,
              label: item.activityTitle,
            };
          });
          this.reviewLoading = false;
        })
        .catch((err) => {
          console.log("获取活动列表失败", err);
          this.reviewLoading = false;
        });
    },
    handleAvatarSuccess(files) {
      this.ruleForm.photoIdListId = [files[0].fileId];
      this.imageUrl =
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        files[0].fileId;
      this.uploadLoading = false;
      this.$refs.ruleForm.validateField("photoIdListId");
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error("封面图片只能是 JPG,PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("封面图片大小不能超过 2MB!");
      }
      const returnFlag = (isJPG || isPNG) && isLt2M;
      this.uploadLoading = returnFlag;
      return returnFlag;
    },
    deleteHandler() {
      this.ruleForm.photoIdListId = [];
      this.imageUrl = null;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitHandler();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    parseParams(params) {
      switch (this.currentState) {
        case "add":
          params.typeFlag = 0;
          break;
        case "review":
          params.typeFlag = 2;
          break;
        case "edit":
          params.typeFlag = this.typeFlag;
          params.id = this.id;
          break;
      }
      console.log("this.reviewValue", this.reviewValue);
      if (this.currentState === "review" && this.reviewValue) {
        params.id = this.reviewId;
      }
      return params;
    },
    submitHandler() {
      let params = { ...this.ruleForm };
      params = this.parseParams(params);
      if (this.currentState === "add" || this.currentState === "review") {
        addParkActivity(params).then((res) => {
          if (res.code === 200) {
            this.$message.success("新增活动成功");
            this.$emit("close", true);
          } else {
            this.$message.warning("新增活动失败！");
          }
        });
      } else {
        editParkActivity(params).then((res) => {
          if (res.code === 200) {
            this.$message.success("修改活动成功");
            this.$emit("close", true);
          } else {
            this.$message.warning("修改活动失败！");
          }
        });
      }
    },
    resetForm() {
      this.$refs["ruleForm"].resetFields();
      this.$emit("close", false);
    },
  },
};
</script>

<style scoped lang="less">
.park-activity-dialog {
  .upload-container {
    display: flex;
    .tips-container {
      margin-left: 15px;
      font-size: 12px;
      & > div {
        height: 20px;
        line-height: 20px;
      }
      .delete-icon {
        position: absolute;
        bottom: 0;
        font-size: 14px;
        color: #ff0000;
        cursor: pointer;
      }
    }
  }
  .avatar-uploader {
    border: 1px dotted #ccc;
    height: 178px;
    width: 178px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .review-radio-container {
    display: flex;
    .review-tips {
      font-size: 10px;
      color: #ccc;
      margin-left: 10px;
    }
  }
  ::v-deep .el-radio {
    margin-right: 0;
  }
  ::v-deep .el-form-item__content {
    height: 36px;
  }
  .auto-height-item ::v-deep .el-form-item__content {
    height: auto !important;
  }
}
</style>