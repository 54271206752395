<template>
  <div class="app-container park-activity-comp">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="addNewHandler(0)">
          新增预告
        </el-button>
        <el-button type="primary" @click="addNewHandler(2)">
          新增回顾
        </el-button>
        <el-tooltip
          class="item"
          effect="dark"
          :disabled="parkActivityObj.selectionList.length > 0"
          :content="$t('commons.multipleSelectionOne')"
          placement="right"
        >
          <el-button
            type="danger"
            @click="batchDeleteHandler"
            :class="
              parkActivityObj.selectionList.length === 0 && 'not-allowed-button'
            "
            icon="el-icon-delete"
            plain
            >{{ $t("commons.delete") }}
          </el-button>
        </el-tooltip>
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        <i class="iconfont icon-loudoutu"></i>
        筛选
      </div>
    </div>
    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>

    <!-- 新增dialog -->
    <el-dialog
      :visible.sync="parkActivityObj.dialogVisible"
      :before-close="handleClose"
      width="900px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <span>{{ parkActivityObj.dialogTitle }}</span>
      </template>
      <parkActivityDialog
        v-if="parkActivityObj.dialogVisible"
        :dialogStatus="parkActivityObj.dialogStatus"
        :showAddressList="showAddressList"
        :activityTypeList="activityTypeList"
        :id="parkActivityObj.id"
        :typeFlag="parkActivityObj.typeFlag"
        @close="handleClose"
      />
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import { findLookupItem } from "@/api/ruge/lookup/lookup";
import parkActivityDialog from "./parkActivityDialog";
import { envInfo } from "@/constants/envInfo";
import {
  getParkActivityList,
  deleteParkActivity,
} from "@/api/ruge/bms/customerService/parkActivity";

export default {
  components: {
    parkActivityDialog,
    finalTable,
  },
  data() {
    return {
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: true,
          width: "18",
        },
        header: [
          { prop: "activityTitle", label: "活动名称", width: "" },
          { prop: "activityType", label: "活动类型", width: "" },
          { prop: "showAddress", label: "展示位置", width: "" },
          { prop: "publisher", label: "发布人", width: "" },
          { prop: "pubdate", label: "发布时间", width: "" },
          { prop: "typeFlag", label: "发布类型", width: "" },
          { prop: "operation", label: "操作", width: "155" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          activityTitle: {
            type: "input",
            label: "活动名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入活动名称",
            prefixIcon: "el-icon-search",
          },
          activityType: {
            type: "selector",
            label: "活动类型",
            value: "",
            placeholder: "请选择活动类型",
            actionType: "goSearch",
            optionList: [],
          },
          typeFlag: {
            type: "selector",
            label: "发布类型",
            value: "",
            placeholder: "请选择发布类型",
            actionType: "goSearch",
            optionList: [
              {
                value: "0",
                label: "活动预告",
              },
              {
                value: 1,
                label: "活动结束",
              },
              {
                value: 2,
                label: "活动回顾",
              },
            ],
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          activityType: {
            type: "enumerationColumn",
            emuList: {},
          },
          showAddress: {
            type: "enumerationColumn",
            emuList: {},
          },
          pubdate: {
            type: "dateFormat",
          },
          typeFlag: {
            type: "enumerationColumn",
            emuList: {
              0: "活动预告",
              1: "活动结束",
              2: "活动回顾",
            },
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "详情",
              },

              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          activityTitle: {
            inline: true,
            value: "",
          },
          activityType: {
            inline: true,
            value: "",
          },
          typeFlag: {
            inline: true,
            value: "",
          },
          pubdate: {
            type: "dateRange",
            label: "发布时间",
            value: [],
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      parkActivityObj: {
        selectionList: [],
        dialogVisible: false,
        typeFlag: null,
        /**
         * 新增活动: add
         * 新增回顾: review
         * 详情：view
         * 编辑：edit
         */
        dialogStatus: "add",
        dialogTitle: "发布活动",
        listQuery: {
          current: 1,
          rowCount: 10,
          noticeTitle: null,
          noticeType: null,
          startTime: null,
          endTime: null,
        },
      },
      showAddressList: [],
      activityTypeList: [],
    };
  },
  created() {
    this.dataInit();
  },
  methods: {
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.dealTime(datas);
        this.parkActivityObj.listQuery = {
          ...this.parkActivityObj.listQuery,
          ...datas.params,
        };
        this.parkActivityObj.listQuery.current = 1;
        this.getTableList();
      } else if (datas.type === "paginationChange") {
        this.parkActivityObj.listQuery.current = datas.params.current.page;
        this.parkActivityObj.listQuery.rowCount = datas.params.current.limit;
        this.getTableList();
      } else if (datas.type === "updateSelectionList") {
        this.parkActivityObj.selectionList = datas.list.filter((item) => item);
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.parkActivityObj.dialogTitle =
              datas.row.typeFlag === 1
                ? "活动详情"
                : datas.row.typeFlag === 0
                ? "活动预告详情"
                : "活动回顾详情";
            this.parkActivityObj.id = datas.row.id;
            this.parkActivityObj.dialogStatus = "edit";
            this.parkActivityObj.typeFlag = datas.row.typeFlag;
            this.parkActivityObj.dialogVisible = true;
            break;
          case "view":
            this.parkActivityObj.dialogTitle =
              datas.row.typeFlag === 1
                ? "活动详情"
                : datas.row.typeFlag === 0
                ? "活动预告详情"
                : "活动回顾详情";
            this.parkActivityObj.id = datas.row.id;
            this.parkActivityObj.typeFlag = datas.row.typeFlag;
            this.parkActivityObj.dialogStatus = "view";
            this.parkActivityObj.dialogVisible = true;
            break;
          case "delete":
            this.singleDeleteHandler(datas.row);
            break;
        }
      }
    },
    dealTime(datas) {
      if (datas.params.pubdate && datas.params.pubdate.length > 0) {
        this.parkActivityObj.listQuery.startTime = datas.params.pubdate[0]
          ? new Date(datas.params.pubdate[0]).getTime()
          : null;
        this.parkActivityObj.listQuery.endTime = datas.params.pubdate[1]
          ? new Date(datas.params.pubdate[1]).getTime()
          : null;
        delete datas.params.pubdate;
      }
      // else {
      //   this.parkActivityObj.listQuery.startTime = null;
      //   this.parkActivityObj.listQuery.endTime = null;
      // }
      return datas;
    },
    getTableList() {
      const params = { ...this.parkActivityObj.listQuery };
      getParkActivityList(params).then((res) => {
        this.dataset.pageVO.total = res.data.total;
        this.dataset.tableData = res.data.rows;
      });
    },
    handleClose(freshFlag) {
      this.parkActivityObj.dialogVisible = false;
      freshFlag && this.getTableList();
    },
    addNewHandler(type) {
      this.parkActivityObj.dialogTitle =
        type === 2 ? "发布活动回顾" : "发布活动";
      this.parkActivityObj.dialogStatus = type === 2 ? "review" : "add";
      this.parkActivityObj.id = null;
      this.parkActivityObj.dialogVisible = true;
    },
    dataInit() {
      this.getTableList();
      findLookupItem("CUST_ACTIVITY_TYPE").then((row) => {
        let activityType = {};
        this.dataset.searchLineConfig.activityType.optionList = row.map(
          (item) => {
            activityType[item.itemCode] = item.itemName;
            const curObj = {
              value: item.itemCode,
              label: item.itemName,
            };
            this.activityTypeList.push(curObj);
            return curObj;
          }
        );
        this.dataset.detailConfig.activityType.emuList = activityType;
      });
      findLookupItem("SHOW_ADDRESS").then((row) => {
        let addressObj = {};
        row.forEach((item) => {
          if (item.itemName == "动漫小程序") {
            addressObj[item.itemCode] = item.itemName;
            this.showAddressList.push({
              value: item.itemCode,
              label: item.itemName,
            });
          }
        });
        // this.showAddressList = row.map((item) => {
        //   addressObj[item.itemCode] = item.itemName;
        //   return {
        //     value: item.itemCode,
        //     label: item.itemName,
        //   };
        // });
        this.dataset.detailConfig.showAddress.emuList = addressObj;
      });
    },
    batchUpdate(status) {
      if (this.parkActivityObj.selectionList.length === 0) return;
      this.updateState(this.parkActivityObj.selectionList, status);
    },
    updateState(datas, status) {
      const params = datas.map((item) => {
        return {
          id: item.id,
          status: status,
        };
      });
      updateServiceGuideState(params).then((res) => {
        if (res.code === 200) {
          this.$message.success("状态更新成功！");
          this.getTableList();
        } else {
          this.$message.warning("状态更新失败！");
        }
      });
    },
    batchDeleteHandler() {
      if (this.parkActivityObj.selectionList.length === 0) return;
      this.deleteHandler(
        this.parkActivityObj.selectionList.map((item) => item.id)
      );
    },
    singleDeleteHandler(row) {
      this.deleteHandler([row.id]);
    },
    deleteHandler(ids) {
      this.$confirm(
        "删除后将不可恢复，确定删除么？",
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteParkActivity(ids).then((res) => {
            if (res.code === 200) {
              this.$message.success("删除成功！");
              this.getTableList();
            } else {
              this.$message.warning("删除失败！");
            }
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
  },
};
</script>

<style scoped lang="less">
.park-activity-comp {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }
    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
    .not-allowed-primary-button {
      cursor: not-allowed;
      color: #fff;
      background-color: #a0cfff;
      border-color: #a0cfff;
    }
  }
}
</style>